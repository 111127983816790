@import url(https://fonts.googleapis.com/css?family=Varela+Round&display=swap);
@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap);
@import url(https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.body {
  max-width: 100%;
  font-family: 'varela round';
}

.container {
  background-color:#12B1D3; 
  height: 200px;
  width: 100%;
  text-align: center;
  font-weight: bolder;
  font-size: 25px;
  color: azure;
  position : absolute;
}


  /* term and condition css */
  .font {
    font-family: 'M PLUS Rounded 1c';
  }

  .section-banner {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
    height: 10%;
    text-align: left;
    font-family: 'varela round';
   
  }

  .content{
    max-width: 1000px;
    margin: 0 auto;
    padding: 20px;
    font-family: 'varela round';
  }

  .paragraph{
    text-Align:'inherit';
    line-Height: 2;
  }

  /* F A Q */
  .tab {
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    font-family: 'varela round';

}



